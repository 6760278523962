import './App.css'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Homepage, Information, Tickets, Samtykkeskjema } from "./pages"
import { AnimatedSwitch } from "react-router-transition"
import { Header } from "./components"
import { useState, useEffect } from "react"
import Konami from "react-konami-code"
import { Seatmap } from './pages/Seatmap'
function App() {
  const [rainbowBackground, setRainbowBackground] = useState(false)
  const onKonami = () => {
    console.log("Du fant påskeegget! Nyt fargene.")
    setRainbowBackground(!rainbowBackground)
  }
  useEffect(() => {
    console.log("%cFinn påskeegget!", "color: blue; font-size:32px;")
    console.log("Hint: Du finner det ikke her, men bruk tastaturet.")
  }, [])
  return (
    <div className={`page-container ${(rainbowBackground) ? "rainbow" : ""}`}>
      <Router>
        <Konami action={onKonami} />
        <Header />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 1 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper">
          <Route path="/" exact>
            <Homepage />
          </Route>
          <Route path="/tickets" exact>
            <Tickets />
          </Route>
          <Route path="/info" exact>
            <Information />
          </Route>
          <Route path="/samtykkeskjema" exact>
            <Samtykkeskjema />
          </Route>
          <Route path="/seatmap" exact>
            <Seatmap />
          </Route>
        </AnimatedSwitch>
      </Router>
    </div>
  )
}

export default App
