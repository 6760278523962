import { Button, TicketIcon, BackButton, CountDown } from "../components"
import { Link } from "react-router-dom"
import { useEffect } from "react"

export function Tickets() {
    useEffect(() => {
        document.title = "TorriLAN - Billetter"
    }, [])
    return (
        <>
            <BackButton />
            <div className="wrapper">
                <div className="container">
                    <h1>Billetter</h1>
                    <p>OBS: Ved kjøp av billetter vil du få spørsmål om du har noen helseplager.<br /> Vi ber deg om å kun skrive "JA" om dette gjelder deg, da tar vi kontakt med deg for å høre om du har noen helseplager, allergier o.l. som vi bør ta hensyn til.</p><br />
                    <p>Om du kjøper billett for andre er det viktig at disse bilettene blir overført til en konto i deres navn slik at vi har riktig personalia knyttet opp mot billetten</p><br />
                    <p>Vi minner om at alle deltakere skal ha med seg <b>underskrevet </b><Link style={{ display: "inline-flex", marginRight: "0px" }} to="/samtykkeskjema">Samtykkeskjema</Link>. Her står også alle reglene vi forventer våre deltagere følger.</p>
                    <br /><br />

                    <br />
                    <div className="buttons-wrapper">
                        <a style={{ border: "none", textDecoration: "none" }} href="https://app.checkin.no/event/68038/torrilan-februar-2024" rel="noreferrer" target="_blank">
                            <Button>
                                <TicketIcon size={30} />
                                Gå til billettsalget.
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
