import { DocumentIcon, Button, InfoIcon, TicketIcon } from "../components"
import { useHistory } from "react-router-dom"
import { useEffect } from "react"

export function Homepage() {
    const history = useHistory()
    useEffect(() => {
        document.title = "TorriLAN"
    }, [])
    return (<>
        <div className="wrapper">
            <div className="container">
                <h1>TorriLAN 2024</h1>
                <p>Gjør deg klar for årets LAN!<br />16 - 18 Februar 2024<br />Kjøp billett nå!</p>
                <div className="buttons-wrapper">
                    <Button onClick={() => history.push("/tickets")}>
                        <TicketIcon size={30} />
                        Kjøp billetter
                    </Button>
                    <Button onClick={() => history.push("/info")}>
                        <InfoIcon size={30} />
                        Informasjon
                    </Button>
                    <Button onClick={() => history.push("/samtykkeskjema")}>
                        <DocumentIcon size={30} />
                        Foresatt samtykkeskjema
                    </Button>
                    <Button onClick={() => history.push("/seatmap")}>
                        <DocumentIcon size={30} />
                        Seatmap
                    </Button>
                </div>
            </div>
        </div>
    </>
    )
}
